import { processGoToAuthorization } from '@/helpers/processGoToAuthorization'
import { ApiAuthorization } from 'ApiRest/Api/Auth/Authorization'

/**
 * @param {Object} redirectData
 */
export const logout = async (redirectData) => {
  await ApiAuthorization.delete()

  processGoToAuthorization(redirectData)
}
