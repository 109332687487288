import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setInputValue(state, { key, value }) {
    state.registerData[key] = value
  },

  setSchoolPlatform(state, payload) {
    state.schoolPlatform = payload
  },

  setRegistrationToken(state, payload) {
    state.registrationToken = payload
  },
}
