import { showToast } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiPasswordCreate from 'ApiRest/Api/User/PasswordCreate'
import ApiPasswordCreateTokenCheck from 'ApiRest/Api/User/PasswordCreateTokenCheck'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async createPassword({ state, commit }) {
    const payload = {
      newPassword: state.newPassword,
      confirmNewPassword: state.confirmNewPassword,
      code: state.code,
    }

    const { data } = await ApiPasswordCreate.patch(payload)

    commit('setPasswordCreateToken', data)
  },

  async checkToken({ state, commit }) {
    const payload = {
      code: state.code,
    }

    // TODO: -> component
    try {
      const {
        data: { result },
      } = await ApiPasswordCreateTokenCheck.patch(payload)

      commit('setInputValue', {
        name: 'isCodeValid',
        value: result,
      })
    } catch (error) {
      const status = error?.response?.status

      if (status === HttpStatusCodes.NotFound) {
        const message =
          error?.response?.data?.message ??
          i18n.global.t('store.auth.toasted_message.success.error_has_occurred')

        showToast(message, 'error')
      }
    }
  },
}
