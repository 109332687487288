import ApiBase from 'ApiRest/ApiBase'

export const ApiAuthSwitchToClient = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {string} payload.password
   * @returns {Promise}
   */
  post(payload) {
    return this._POST('/auth/student-to-client-token', payload)
  }
})()
