export default () => ({
  registerData: {
    email: '',
    firstName: '',
    isAgreedWithProcessingPD: false,
    isAgreement: false,
    hasMoscowRegistration: false,
    isNotification: false,
    password: '',
    phone: '',
    utm: '',
  },

  schoolPlatform: null,
  registrationToken: null,
})
