export default () => ({
  form: {
    firstName: '',
    email: '',
    phone: '',
    isAccepted: true,
    grade: null,
  },
  registrationToken: null,
})
